import React, { useMemo } from "react";
import PageLayout from "../../../layouts/PageLayout";
import TrainingCard from "./TrainingCard";
import {
	TrainingVideos,
	MarketingVideos,
} from "../../../constants/TrainingVideos";
import { usePortalUserStore } from "../../../store/PortalUser";

const Training = () => {
	const { portalUser } = usePortalUserStore();

	const onlyViewMarketing = useMemo(() => {
		if (!portalUser) return false;
		return portalUser?.emailAddress?.endsWith("+guest@xonar.com");
	}, [portalUser]);

	const onlyViewTraining = useMemo(() => {
		if (!portalUser) return false;
		return portalUser?.emailAddress?.endsWith("+training@xonar.com");
	}, [portalUser]);

	return (
		<PageLayout
			title="Training Videos"
			description="Training videos to assist you with your Xonar devices and apps."
		>
			<div className="grid grid-cols-1 gap-4 divide-y-2 divide-gray-100 dark:divide-gray-600">
				{onlyViewMarketing ? (
					MarketingVideos.map((video) => (
						<TrainingCard key={video.videoId} {...video} />
					))
				) : onlyViewTraining ? (
					TrainingVideos.map((video) => (
						<TrainingCard key={video.videoId} {...video} />
					))
				) : (
					<>
						{MarketingVideos.map((video) => (
							<TrainingCard key={video.videoId} {...video} />
						))}
						{TrainingVideos.map((video) => (
							<TrainingCard key={video.videoId} {...video} />
						))}
					</>
				)}
			</div>
		</PageLayout>
	);
};

export default Training;
