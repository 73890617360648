type TrainingVideoType = "trueport" | "portal" | "app" | "truescan";

type TrainingVideo = {
	title: string;
	description: string;
	videoId: string;
	videoType: TrainingVideoType;
};

export const TrainingVideos: TrainingVideo[] = [
	{
		title: "How to Setup TruePort",
		description: "Learn how to setup your TruePort devices.",
		videoId: "2jnMJc0hHuQ",
		videoType: "trueport",
	},
	{
		title: "How to Operate TruePort",
		description: "Learn how to operate your TruePort devices.",
		videoId: "_jqYOu5TkaE",
		videoType: "trueport",
	},
	{
		title: "How to Configure TruePort Using the LCD Screen",
		description:
			"Learn how to configure your TruePort devices using the LCD screen.",
		videoId: "V6mUW7aFjHk",
		videoType: "trueport",
	},
	{
		title: "How to Load and Unload TruePort",
		description:
			"Learn how to load and unload your portable TruePort devices.",
		videoId: "ug30cGF3G_4",
		videoType: "trueport",
	},
	{
		title: "Setting up the TruePort Floor Mats",
		description:
			"Learn some tips on how to set up the TruePort floor mats.",
		videoId: "57k1k_3RQWc",
		videoType: "trueport",
	},
	{
		title: "How to Use the My Xonar Portal",
		description: "Learn how to use the My Xonar Portal.",
		videoId: "_7tJkHPU6Ok",
		videoType: "portal",
	},
];

export const MarketingVideos: TrainingVideo[] = [
	{
		title: "TruePort Item Detection Demo",
		description: "",
		videoId: "FjpfMRotau0",
		videoType: "trueport",
	},
	{
		title: "Ticketing",
		description: "",
		videoId: "xzCg90BwkXk",
		videoType: "trueport",
	},
	{
		title: "First Look at TrueScan",
		description: "",
		videoId: "nxashqbm7GQ",
		videoType: "truescan",
	},
	{
		title: "Setup Timelapse",
		description: "",
		videoId: "xQB5UEX0ML0",
		videoType: "trueport",
	},
	{
		title: "Multilane with TrueScan",
		description: "",
		videoId: "TotINKjibUw",
		videoType: "trueport",
	},
];

export const AllVideos = [...TrainingVideos, ...MarketingVideos];
