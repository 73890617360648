import React, { useMemo, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PageLayout from "../../../layouts/PageLayout";
import LoadingLayout from "../../../layouts/LoadingLayout";
import { AllVideos } from "../../../constants/TrainingVideos";
import YouTube from "react-youtube";
import { useResponsive } from "../../../hooks/useResponsive";

type VideoSize = {
	width: number;
	height: number;
	viewport: string;
};

const videoSizes: VideoSize[] = [
	{
		viewport: "sm",
		width: 640,
		height: 360,
	},
	{
		viewport: "md",
		width: 768,
		height: 432,
	},
	{
		viewport: "lg",
		width: 1024,
		height: 576,
	},
	{
		viewport: "xl",
		width: 1280,
		height: 720,
	},
	{
		viewport: "2xl",
		width: 1536,
		height: 864,
	},
];

const videoViews = {
	sm: {
		viewport: "sm",
		width: 500,
		height: 281,
	},
	md: {
		viewport: "md",
		width: 640,
		height: 360,
	},
	lg: {
		viewport: "lg",
		width: 968,
		height: 544,
	},
	xl: {
		viewport: "xl",
		width: 1080,
		height: 607,
	},
	"2xl": {
		viewport: "2xl",
		width: 1280,
		height: 720,
	},
};

const getVideoInfo = (videoId: string | undefined) => {
	if (!videoId) {
		return undefined;
	}
	return AllVideos.find((video) => video.videoId === videoId);
};

const TrainingVideo = () => {
	const { videoId } = useParams();

	const navigate = useNavigate();

	const videoInfo = useMemo(() => getVideoInfo(videoId), [videoId]);

	const responsive = useResponsive();

	const videoSize = useMemo(() => {
		let width;
		let height;
		let size;
		if (responsive.isXl) {
			width = videoViews["xl"].width;
			height = videoViews["xl"].height;
		} else if (responsive.isLg) {
			width = videoViews["lg"].width;
			height = videoViews["lg"].height;
		} else if (responsive.isMd) {
			width = videoViews["md"].width;
			height = videoViews["md"].height;
		} else if (responsive.isSm) {
			width = videoViews["sm"].width;
			height = videoViews["sm"].height;
		} else {
			width = 300;
			height = 225;
		}
		size = {
			width: `${width}px`,
			height: `${height}px`,
		};
		return size;
	}, [responsive]);

	useEffect(() => {
		if (!videoInfo) {
			navigate("/error", {
				state: {
					status: 404,
					error: "Video could not be found.",
				},
			});
		}
	}, [videoInfo, navigate]);

	if (!videoId) {
		return <LoadingLayout />;
	}

	return (
		<PageLayout
			title={videoInfo?.title}
			description={videoInfo?.description}
		>
			<div className="aspect-video">
				<YouTube
					videoId={videoId}
					opts={{
						playerVars: {
							autoplay: 1,
						},
						...videoSize,
					}}
				/>
			</div>
		</PageLayout>
	);
};

export default TrainingVideo;
